<template>
  <div v-if="customer" class="p-link-line">
    <!-- 連携時 -->
    <template v-if="isLinked">
      <LinkRelease v-if="hasReleased" />
      <AlreadyLink v-else />
    </template>

    <!-- 未連携時 -->
    <template v-else>
      <LinkLine
        v-if="page === 1"
        :mile="mile"
        :coupon="coupon"
        :point="point"
        :loading="isProcessing" />
      <LinkComplete v-else />
    </template>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  computed,
  onBeforeMount
} from '@vue/composition-api';
import { SystemDialogMessage } from '@/constants/enums';
import { useNativeConnection } from '@/composables/useNativeConnection';
const { setMileage } = useNativeConnection();

export default defineComponent({
  components: {
    LinkLine: () => import('@/components/link/line/LinkLine.vue'),
    LinkComplete: () => import('@/components/link/line/LinkComplete.vue'),
    AlreadyLink: () => import('@/components/link/line/AlreadyLink.vue'),
    LinkRelease: () => import('@/components/link/line/LinkRelease.vue')
  },

  setup: (props, context) => {
    const customer = ref(null);
    const page = ref(1);

    /**
     * LINEアカウントと連携済みかどうか
     *
     * @returns {boolean}
     */
    const isLinked = computed(
      () => customer.value?.line?.is_alignment || false
    );

    /**
     * 連携解除画面を表示するかどうか
     */
    const hasReleased = computed(() => 'release' in context.root.$route.query); // クエリパラメータに Key: `release` が含まれているかどうか

    /**
     * LINE連携時に取得するマイル数（過去にLINE連携したことがある場合は0）
     *
     * @returns {number}
     */
    const mile = computed(() =>
      customer.value?.line?.is_past_alignment
        ? 0
        : customer.value?.line?.mileage_amount || 0
    );

    /**
     * LINE連携時に取得するオンラインストアクーポン（過去にLINE連携したことがある場合はfalse）
     *
     * @returns {boolean}
     */
    const coupon = computed(() => customer.value?.line?.coupon || false);

    /**
     * LINE連携時に取得するオンラインストアポイント（過去にLINE連携したことがある場合は0）
     *
     * @returns {number}
     */
    const point = computed(() => customer.value?.line?.point || 0);

    const isProcessing = computed(() => !!context.root.$route.query.token);

    const link = async (token) => {
      try {
        context.root.$_loading_start();

        const { data } = await context.root
          .$repositories('line')
          .link({ token });
        if (data.mileage.plus_mileage > 0) {
          // マイル獲得ダイアログの呼び出し
          await setMileage({
            now_mileage: data.mileage.now_mileage,
            plus_mileage: data.mileage.plus_mileage,
            is_stage_up: data.mileage.rank_up,
            is_not_empty_present: !!data.present[0],
            stage_name_before: data.mileage.stage?.before.name,
            stage_name_after: data.mileage.stage?.after.name,
            stage_name_next: data.mileage.stage?.next.name,
            wait_for_load_complete: false
          });
        }
        page.value++;
      } catch {
        window.location.href = context.root.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      } finally {
        context.root.$_loading_stop();
      }
    };

    const getCustomer = async () => {
      try {
        context.root.$_loading_start();

        const { data } = await context.root
          .$repositories('customer')
          .getCustomer();
        customer.value = data;
      } catch {
        window.location.href = context.root.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      } finally {
        context.root.$_loading_stop();
      }
    };

    onBeforeMount(async () => {
      await getCustomer();

      if (context.root.$route.query.token) {
        await link(context.root.$route.query.token);
      }
    });

    return {
      customer,
      page,
      isLinked,
      hasReleased,
      mile,
      isProcessing,
      coupon,
      point
    };
  }
});
</script>
