var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.customer
    ? _c(
        "div",
        { staticClass: "p-link-line" },
        [
          _vm.isLinked
            ? [_vm.hasReleased ? _c("LinkRelease") : _c("AlreadyLink")]
            : [
                _vm.page === 1
                  ? _c("LinkLine", {
                      attrs: {
                        mile: _vm.mile,
                        coupon: _vm.coupon,
                        point: _vm.point,
                        loading: _vm.isProcessing,
                      },
                    })
                  : _c("LinkComplete"),
              ],
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }